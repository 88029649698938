import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    cursor: 'default',
  },

  title: {
    marginBottom: 10,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: 10,
  },
  text1: {
    marginBottom: 10,
    fontWeight: 'lighter',
  },
  text2: {
    marginBottom: 10,
  },
  item: {
    textIndent: 10,
  },
});

function Privacy() {
  const classes = useStyles();
  return (
    <div>
      <CssBaseline />
      <Container maxWidth="lg">
        <Card className={classes.card}>
          <CardActionArea className={classes.card}>
            <CardContent className={classes.card}>
              <Typography gutterBottom variant="h3" component="h1" className={classes.title}>
                POLÍTICA DE PRIVACIDADE
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.text1}>
                Quando o USUÁRIO aceita essa Política de Privacidade confere sua livre e expressa concordância com os termos
                aquiestipulados​.
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text2}>
                Este documento tem por finalidade estabelecer as regras para tratamento de dados que incluem,
                exemplificativamente, as operações de coleta, produção, recepção, classificação, utilização, acesso, reprodução,
                transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da
                informação, modificação, comunicação, transferência, difusão ou extração dos dados coletados dos USUÁRIOS, além
                do registro de suas atividades, de acordo com as leis aplicáveis.
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Quando o USUÁRIO aceita essa Política de Privacidade confere sua livre e expressa concordância com os termos
                  aqui estipulados.
                </u>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                1. Glossário
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>1.1.</b> Para os fins deste documento, devem ser consideradas as seguintes definições e descrições para seu
                  melhor entendimento:
                </p>

                <p>
                  <b>Conta</b>: é a forma através da qual o USUÁRIO é representado ao acessar determinadas áreas restritas,
                  funcionalidades exclusivas dos sites, aplicativos e serviços oferecidos pelo CROOKES, geralmente
                  correspondendo a um conjunto de dados que representa o USUÁRIO (como dados cadastrais) e outros dados
                  relevantes para garantir relação mais apropriada e completa do CROOKES com o USUÁRIO (como registro de
                  atividades efetuadas pelo USUÁRIO nos sites e serviços oferecidos pelo CROOKES).
                </p>

                <p>
                  <b>Cookies</b>: pequenos arquivos ou pacotes de dados enviados pelo CROOKES ao dispositivo do USUÁRIO para
                  identificá-lo e coletar informações que auxiliarão o CROOKES a aprimorar os serviços prestados aos USUÁRIO.
                </p>

                <p>
                  <b>Credenciais</b>: é o conjunto de dados que o USUÁRIO usa para se autenticar quando acessa determinadas
                  áreas restritas e/ou funcionalidades exclusivas dos sites, aplicativos e serviços oferecidos pelo CROOKES.
                  Geralmente tais dados são login e senha, mas podem incluir dados adicionais que auxiliem no processo de
                  autenticação. Tais dados jamais devem ser compartilhados, informação que declaram conhecimento.
                </p>

                <p>
                  <b>Dados</b>: conjunto de Dados Anonimizados e Dados Pessoais.
                </p>

                <p>
                  <b>Dados Anonimizados</b>: são informações que, isoladamente ou em conjunto com outros Dados Anonimizados, não
                  permitem a identificação de uma pessoa, considerando a utilização de meios técnicos razoáveis e disponíveis na
                  ocasião de seu tratamento. Podem incluir gênero, idade e geolocalização (como a cidade em que se encontra) e
                  dados estatísticos.
                </p>

                <p>
                  <b>Dados Pessoais</b>: são informações relacionadas à pessoa natural identificada ou identificável. Podem
                  incluir, por exemplo, nome, endereço, e-mail, telefone, número de cartão de débito/crédito, endereço IP e
                  dados de geolocalização. Encarregado ou Data Protection Officer.
                </p>

                <p>
                  <b>(DPO)</b>: pessoa (física ou jurídica) responsável por atuar como canal de comunicação entre o CROOKES,
                  o USUÁRIO e autoridades governamentais em assuntos relacionados a essa Política de Privacidade e ao uso,
                  coleta e tratamento de Dados pelo CROOKES.
                </p>

                <p>
                  <b>CROOKES</b>: pessoa jurídica, inscrita sob o CNPJ. 33.443.316/0001-07. Razão Social. CROOKES INTERMEDIACAO
                  DE SERVICOS MEDICOS LTDA.
                </p>

                <p>
                  <b>Endereço IP</b>: endereço de Internet Protocol associado ao dispositivo usado pelo USUÁRIO. Cada Endereço
                  IP corresponde a um conjunto alfanumérico que, junto com outras informações, ajuda a identificar unicamente o
                  dispositivo que o USUÁRIO está usando para acessar a internet e, portanto, para acessar sites, aplicativos e
                  serviços oferecidos pelo CROOKES.
                </p>

                <p>
                  <b>Legislação Aplicável</b>: significa a legislação aplicável ao relacionamento entre o CROOKES.e o USUÁRIO,
                  que pode variar por conta de (i) local de prestação dos serviços; (ii) local de residência ou moradia de uma
                  das Partes, incluindo o próprio USUÁRIO; (iii) outros fatores apontados em legislações específicas. Logs:
                  registros de atividades dos USUÁRIOS efetuados nos sites, aplicativos e serviços prestados pelo CROOKES.
                </p>

                <p>
                  <b>Política de Privacidade</b>: significa, em conjunto, esta Política de Privacidade.
                </p>

                <p>
                  <b>USUÁRIOS</b>: pessoas que acessam ou interagem com as funcionalidades oferecidas pelos sites, aplicativos e
                  serviços oferecidos pelo CROOKES. O USUÁRIO deverá ter capacidade legal para aceitar e consentir com a
                  presente Política de Privacidade e demais documentos do CROOKES. Caso não detenha tal capacidade,
                  o USUÁRIO declara ter obtido previamente todas as autorizações necessárias para aceitar esta Política de
                  Privacidade e demais documentos apresentados pelo CROOKES.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Coletamos os Dados do USUÁRIO conforme ele nos fornece, de forma direta ou indireta, no acesso e uso dos
                  sites, aplicativos e serviços oferecidos pelo  CROOKES ou por parceiros que tenham sido devidamente
                  autorizados pelo  CROOKES. Também explicamos no que consistem os Cookies e como o USUÁRIO pode gerenciá-los.
                </u>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                2. Coleta dos Dados
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.1.</b>Os Dados serão coletados:
                </p>

                <p>
                  <b>(i)</b> quando inseridos ou submetidos voluntariamente pelos USUÁRIOS nos sites, aplicativos e serviços
                  oferecidos pelo CROOKES, tais como a criação de Conta, navegação, interação com conteúdo e aquisição de
                  serviços;
                </p>

                <p>
                  <b>(ii)</b> quando o USUÁRIO submeter Dados de terceiros ao CROOKES poderá utilizá-los de acordo com a
                  presente Política de Privacidade, declarando o USUÁRIO que obteve o consentimento e autorização necessários
                  dos terceiros em questão para submeter esses Dados para conhecimento e registro por parte do CROOKES, mantendo
                  o CROOKES indene;
                </p>

                <p>
                  <b>(iii)</b> quando os Dados forem submetidos de forma automatizada e sem a necessidade de qualquer ação por
                  parte dos USUÁRIOS, tais como por meio de Cookies; ou
                </p>

                <p>
                  <b>(iv)</b> de parceiros que tenham obtido autorização para compartilhá-los com o CROOKES.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.2.</b> As informações que o CROOKES coleta podem incluir, mas não se limitam a:
                </p>

                <p>
                  <b>(i)</b> nome;
                </p>

                <p>
                  <b>(ii)</b> gênero;
                </p>

                <p>
                  <b>(iii)</b> CPF;
                </p>

                <p>
                  <b>(iv)</b> endereço de e-mail;
                </p>

                <p>
                  <b>(v)</b> endereço postal;
                </p>

                <p>
                  <b>(vi)</b> número de telefone e gravações (havendo contato com o USUÁRIO);
                </p>

                <p>
                  <b>(vii)</b> data de nascimento;
                </p>

                <p>
                  <b>(viii)</b> informações de pagamento;
                </p>

                <p>
                  <b>(ix)</b> informações sobre o navegador e sistema operacional do dispositivo;
                </p>

                <p>
                  <b>(x)</b> endereço IP;
                </p>

                <p>
                  <b>(xi)</b> páginas visitadas;
                </p>

                <p>
                  <b>(xii)</b> links e botões clicados;
                </p>

                <p>
                  <b>(xiii)</b> agenda de contatos; e
                </p>

                <p>
                  <b>(xiv)</b> dados biométricos (tais como, mas não limitado a, foto do rosto).
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.2.1.</b> Demais dados coletados pelo CROOKES estão definidos no item 7 desta Política, separados por
                  empresas e, quando aplicável, grupos de produtos/serviços.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.3.</b> O CROOKES  não é responsável pela veracidade, inveracidade ou desatualização nas informações
                  e Dados fornecidos pelo USUÁRIO, sendo de responsabilidade do USUÁRIO prestá-las com exatidão e atualizá-las.
                  O CROOKES poderá solicitar, periodicamente, que o USUÁRIO atualize as informações prestadas e
                  os Dados fornecidos.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.4.</b> O CROOKES utiliza Cookies e identificadores anônimos para controle de audiência, navegação,
                  segurança e publicidade, sendo que o USUÁRIO concorda com essa utilização ao aceitar essa Política de
                  Privacidade. Os Cookies utilizados pelo CROOKES podem ser classificados de diversas formas:
                </p>

                <p>
                  <b>(i)</b> Quanto à validade:
                </p>

                <p className={classes.item}>
                  <b>a)</b> de Sessão: são temporários e permanecem até que a página web ou o navegador sejam fechados. Podem
                  ser utilizados em e-commerce (para o USUÁRIO continuar navegando e não perder o que colocou no carrinho, por
                  exemplo), para analisar padrões de tráfego na internet e para proporcionar melhor experiência e conteúdo
                  contextualizado aos USUÁRIOS.
                </p>

                <p className={classes.item}>
                  <b>b)</b> Permanente: persistem mesmo que o navegador tenha sido fechado. Podem ser utilizados para lembrar
                  informações de login e senha dos USUÁRIOS, por exemplo, ou para garantir uma melhor experiência ao usuário
                  entre diferentes sessões.
                </p>

                <p>
                  <b>(ii)</b> Quanto à propriedade:
                </p>

                <p className={classes.item}>
                  <b>a)</b> Próprios: são de titularidade do CROOKES, que possui total controle sobre os Cookies.
                </p>

                <p className={classes.item}>
                  <b>b)</b> de Terceiros: são de titularidades de terceiros, mas incluídos no dispositivo dos USUÁRIOS mediante
                  os sites, aplicativos e demais serviços oferecidos pelo CROOKES.
                </p>

                <p>
                  <b>(iii)</b> Quanto à finalidade:
                </p>

                <p className={classes.item}>
                  <b>a)</b> estritamente necessários: essenciais para permitir ao USUÁRIO utilizar os sites, aplicativos e
                  serviços oferecidos pelo CROOKES, os quais não poderiam ser corretamente oferecidos sem esses Cookies. São
                  geralmente de Sessão e Próprios.
                </p>

                <p className={classes.item}>
                  <b>b)</b> de performance: coletam informações anônimas sobre como os USUÁRIOS utilizam e interagem com os
                  sites, aplicativos e serviços oferecidos pelo CROOKES, permitindo reconhecer seus perfis e contabilizar as
                  visitas e interações. São geralmente Próprios.
                </p>

                <p className={classes.item}>
                  <b>c)</b> de funcionalidade: permitem ao CROOKES lembrar escolhas feitas pelos USUÁRIOS (tais como login e
                  localização) e proporcionam experiências mais pessoais, além de possibilitar eventuais customizações (quando
                  estiverem disponíveis). Essas informações podem ser anonimizadas (transformando-se em Dados Anonimizados) e
                  não rastreiam atividades fora dos sites, aplicativos e serviços oferecidos pelo CROOKES. São geralmente
                  Próprios e Permanentes.
                </p>

                <p className={classes.item}>
                  <b>d)</b> analíticos e de publicidade: permitem aos anunciantes do CROOKES entregar anúncios e informações
                  mais relevantes aos USUÁRIOS. Também são utilizados para limitar o número de vezes que o USUÁRIO visualiza
                  determinados anúncios e medir a efetividade de campanhas publicitárias. Eles lembram determinadas preferências
                  dos USUÁRIOS e são utilizados para auxiliar a traçar seus perfis visando melhorar a experiência do USUÁRIO.
                  São geralmente permanentes e podem ser de Terceiros (tais como o Google Analytics, mencionado abaixo).
                </p>

                <p className={classes.item}>
                  <b>e)</b> de segurança: ajudam o CROOKES a monitorar atividades fraudulentas e proteger os dados do usuário em
                  acesso não autorizados. São geralmente permanentes e de Terceiros.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.5.</b> O USUÁRIO pode não aceitar os Cookies para utilizar os sites, aplicativos e serviços oferecidos
                  pelo CROOKES, mas nestas situações o CROOKES não pode garantir o correto funcionamento desses sites,
                  aplicativos e serviços oferecidos. Os Cookies podem ser aceitos, excluídos ou rejeitados por meio de
                  ferramentas de gerenciamento do próprio navegador usado pelo USUÁRIO.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.6.</b> O CROOKES utiliza o Google Analytics e o Google ReCaptcha para a coleta e processamento de Dados,
                  conforme políticas do Google disponíveis em 
                  <a
                    href="https://www.google.com/intl/pt-BR/policies/privacy/partners/"
                    title="omo o Google usa informações de sites ou apps que utilizam nossos serviços"
                  >
                    https://www.google.com/intl/pt-BR/policies/privacy/partners/
                  </a>
                  , 
                  <a
                    href="https://policies.google.com/privacy"
                    title="Quando você usa nossos serviços, está confiando a nós suas informações. Entendemos que isso é uma grande responsabilidade e trabalhamos duro para proteger essas informações e colocar você no controle."
                  >
                    https://policies.google.com/privacy
                  </a>
                   e 
                  <a href="https://policies.google.com/terms" title="O que estes termos cobrem">
                    https://policies.google.com/terms
                  </a>{' '}
                  com as quais o USUÁRIO plenamente concorda ao aceitar a presente Política de Privacidade. Quaisquer usos
                  feitos pelo Google ou seus parceiros dos Dados do USUÁRIO coletados por meio dessas ferramentas serão de
                  responsabilidade única e exclusiva do Google, sendo o CROOKES isento de quaisquer responsabilidades
                  resultantes de tal uso.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.7.</b> O CROOKES também registrará as atividades efetuadas pelo USUÁRIO nos sites, aplicativos e serviços
                  prestados pelo CROOKES criando logs que podem incluir, mas não se limitam a:
                </p>

                <p>
                  <b>(i)</b> Endereço IP do USUÁRIO;
                </p>

                <p>
                  <b>(ii)</b> Ações efetuadas pelo USUÁRIO nos sites, aplicativos e serviços prestados pelo CROOKES;
                </p>

                <p>
                  <b>(iii)</b> Endereços das páginas e telas acessadas pelo USUÁRIO nos sites, aplicativos e serviços prestados
                  pelo CROOKES;
                </p>

                <p>
                  <b>(iv)</b> Datas e horários de cada ação do USUÁRIO nos sites, aplicativos e serviços prestados pelo CROOKES,
                  além do acesso que fizer às páginas e telas e das ferramentas e funcionalidades que utilizar;
                </p>

                <p>
                  <b>(v)</b> Informações sobre o dispositivo utilizado pelo USUÁRIO, versão de sistema operacional, navegador,
                  dentre outros aplicativos e softwares instalados;
                </p>

                <p>
                  <b>(vi)</b> Session e User ID, quando disponível;
                </p>

                <p>
                  <b>(vii)</b> Tipo de conexão do USUÁRIO, tais como Wi-Fi ou redes de celular (EDGE, 3G e 4G, por exemplo); e
                </p>

                <p>
                  <b>(viii)</b> Geolocalização.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>2.8.</b> Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação do USUÁRIO. No
                  entanto respeitarão sempre os termos desta Política de Privacidade e as opções do USUÁRIO a respeito de sua
                  coleta e armazenamento, sendo que o USUÁRIO será informado previamente em caso de quaisquer alterações. Caso
                  outros Dados que não os listados anteriormente passem a ser coletados, o USUÁRIO será previamente informado.
                  As informações coletadas pelo CROOKES poderão ser combinadas com informações coletadas por outras fontes
                  (inclusive parceiros terceiros) ou provenientes de outras tecnologias.
                </p>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                3. Uso dos Dados
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Os Dados poderão ser acessados apenas pelas empresas do CROOKES, parceiros, subcontratados e autoridades.
                  Porém, caso o USUÁRIO acesse funcionalidades ou sites de parceiros, estará sujeito às práticas e políticas
                  próprias desses parceiros. Também elencamos os usos que faremos dos Dados coletados dos USUÁRIOS.
                </u>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.1.</b> Os Dados coletados dos USUÁRIOS poderão ser utilizados para as seguintes finalidades:
                </p>

                <p>
                  <b>(i)</b> Identificação, autenticação e autorização;
                </p>

                <p>
                  <b>(ii)</b> Atender adequadamente às solicitações e dúvidas, bem como prestar suporte aos USUÁRIOS;
                </p>

                <p>
                  <b>(iii)</b> Manter atualizados cadastros para fins de contato por telefone, correio eletrônico, SMS, mala
                  direta ou por outros meios de comunicação;
                </p>

                <p>
                  <b>(iv)</b> Aperfeiçoar o uso e a experiência interativa durante navegação nos sites, aplicativos e serviços
                  prestados pelo CROOKES;
                </p>

                <p>
                  <b>(v)</b> Efetuar estatísticas, estudos, pesquisas, planejamento de projetos e levantamentos pertinentes às
                  atividades e comportamentos do USUÁRIO ao utilizar os sites, aplicativos e serviços prestados pelo CROOKES,
                  realizando tais operações de forma anonimizada com Dados Anonimizados;
                </p>

                <p>
                  <b>(vi)</b> Promover os serviços do CROOKES e de seus parceiros, além de informar sobre novidades,
                  funcionalidades, conteúdos, notícias e demais informações relevantes para a manutenção do relacionamento com
                  o CROOKES;
                </p>

                <p>
                  <b>(vii)</b> Resguardar o CROOKES de direitos e obrigações relacionadas ao uso dos sites, aplicativos e
                  serviços prestados pelo CROOKES;
                </p>

                <p>
                  <b>(viii)</b> Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa, bem como
                  atender obrigações de reportar preventivamente determinadas atividades para autoridades competentes;
                </p>

                <p>
                  <b>(ix)</b> Prosseguir com pedidos de pagamento efetuados pelos USUÁRIOS;
                </p>

                <p>
                  <b>(x)</b> Enviar newsletters e e-mails que o USUÁRIO expressamente concordou em receber;
                </p>

                <p>
                  <b>(xi)</b> Gerenciar riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades potencialmente
                  ilegais ou proibidas, além de violações de políticas ou termos de uso aplicáveis;
                </p>

                <p>
                  <b>(xii)</b> Compartilhar informações de pagamento entre as empresas do CROOKES, visando facilitar a
                  experiência dos USUÁRIOS ao utilizar os sites, aplicativos e serviços oferecidos pelo CROOKES; e
                </p>

                <p>
                  <b>(xiii)</b> Consultar os Dados em bureaus privados e públicos para verificar se estão corretos, atualizá-los
                  ou solicitar dados complementares.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.2.</b> O uso, acesso e compartilhamento da base de dados formada nos termos da presente Política de
                  Privacidade serão feitos dentro dos limites e propósitos das atividades do CROOKES, podendo ser fornecidas e
                  disponibilizadas para acesso e/ou consulta para as empresas integrantes do CROOKES além de parceiros de
                  negócios, fornecedores, prestadores de serviço, subcontratados, autoridades ou terceiros em geral, desde que
                  obedecido o disposto na presente Política de Privacidade, na Legislação Aplicável ou por determinação
                  judicial.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.2.1.</b> O compartilhamento dos Dados dos USUÁRIOS poderá ser feito com entidades localizadas fora do
                  país de residência do USUÁRIO (tais como países da Área Econômica Europeia, da América do Sul ou da América do
                  Norte). Nesses casos, o processamento, tratamento, uso e compartilhamento dos Dados será feito de acordo com a
                  Legislação Aplicável e a presente Política de Privacidade. Em especial, quando houver a transferência
                  de Dados originados da Área Econômica Europeia para países que não tenham sido reconhecidos pelas autoridades
                  competentes como oferecendo nível adequado de proteção, o CROOKES garantirá o uso das ferramentas e medidas
                  apropriadas (tais como as Cláusulas Contratuais Padrão da Comissão Europeia).
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.2.2.</b> O CROOKES se compromete a resguardar o sigilo financeiro e bancário de seus clientes
                  e USUÁRIOS através do não compartilhamento dessas informações com empresas com as quais o USUÁRIO não tenha
                  concordado. O USUÁRIO reconhece que o CROOKES é obrigado a atender uma série de obrigações legais em
                  decorrência da Legislação Aplicável relacionadas com a identidade de seus clientes e a origem dos fundos que
                  opera. Dessa forma, o CROOKES fica autorizado a solicitar, e o USUÁRIO obrigado a fornecer, documentos e
                  informações adicionais, inclusive para realização de procedimentos de conheça-o-seu-cliente e prevenção à
                  lavagem de dinheiro. As informações obtidas pelo CROOKES em resposta aos questionamentos apontados serão
                  tratadas como informações sigilosas. O CROOKES fica autorizado a compartilhar dados exigidos pela Legislação
                  Aplicável com as autoridades competentes e com terceiros que necessitem ter acesso aos Dados para
                  elaborar/validar relatórios sobre as atividades do CROOKES sob referida legislação.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.2.3.</b> O USUÁRIO é responsável pelo sigilo, utilização e todos os atos no uso das Credenciais (tais
                  como, mas não limitados a, login, senha e token de autenticação). Caso o USUÁRIO identifique ou desconfie que
                  um terceiro tenha acesso à sua senha, este deverá alterar diretamente nos sistemas ou serviços. O
                  compartilhamento de Credenciais decorrente de ação do USUÁRIO configura violação a esta Política de
                  Privacidade.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.3.</b> Desde já o USUÁRIO está ciente que o CROOKES poderá realizar <b>(i)</b> a anonimização dos Dados
                  Pessoais, tornando-os Dados Anonimizados; <b>(ii)</b> o enriquecimento da sua base de dados, adicionando
                  informações oriundas de outras fontes legítimas - inclusive decorrentes de bases de dados de outras empresas
                  do CROOKES; e <b>(iii)</b> tratamento de Dados Pessoais com base no legítimo interesse do CROOKES, conforme
                  previsto na Legislação Aplicável. O USUÁRIO manifesta consentimento expresso com tais atividades ao concordar
                  com os termos da presente Política de Privacidade.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.3.1.</b> O USUÁRIO reconhece e concorda que o CROOKES poderá utilizar os Dados para traçar o perfil
                  do USUÁRIO, de forma a aprimorar os seus serviços ou atender a Legislação Aplicável, incluindo, mas não se
                  limitando, obrigações de conheça-o-seu-cliente. O perfil do USUÁRIO será compartilhado exclusivamente nos
                  termos da presente Política de Privacidade ou da Legislação Aplicável.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.4.</b> Internamente, os Dados somente serão acessados por profissionais devidamente autorizados
                  pelo CROOKES, respeitando os princípios da finalidade, adequação e necessidade, entre outros previstos na
                  Legislação Aplicável, para os objetivos do CROOKES, além do compromisso de confidencialidade e preservação da
                  privacidade nos termos desta Política de Privacidade.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.5.</b> Os Dados Anonimizados poderão ser coletados, tratados, armazenados, utilizados, transferidos e
                  divulgados para qualquer finalidade, independentemente de autorização do USUÁRIO.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.6.</b> O CROOKES possui parceiros comerciais que podem oferecer serviços por meio de funcionalidades ou
                  sites acessados a partir dos sites, aplicativos e serviços prestados pelo CROOKES. Os Dados fornecidos
                  pelo USUÁRIO a estes parceiros serão de responsabilidade dos parceiros, estando assim sujeitos às suas
                  próprias práticas de obtenção e uso de Dados, sem que caiba qualquer ônus ao CROOKES com relação a
                  esses Dados.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.7.</b> O CROOKES preza pela segurança e inviolabilidade das Credenciais do USUÁRIO. Por esse motivo
                  constantemente busca e monitora a internet em suas diversas camadas com a finalidade de identificar, coletar e
                  tratar para fins de validação (testes de autenticação) logins e senhas potencialmente pertencentes
                  aos USUÁRIOS que tenham sido indevidamente publicados na internet por terceiros.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>3.7.1.</b> Se por meio de testes de autenticação o CROOKES constatar que as Credenciais do USUÁRIO foram
                  comprometidas (tornadas acessíveis para terceiros), o CROOKES - por segurança e no intuito de impedir o acesso
                  inapropriado de terceiros à Conta e Dados do USUÁRIO - bloqueará preventivamente as Credenciais do USUÁRIO.
                  Havendo quaisquer dúvidas - inclusive sobre como desbloquear as Credenciais - o USUÁRIO pode consultar os
                  termos de uso nos sites, aplicativos e serviços prestados pelo CROOKES, ou entrar em contato com a central de
                  atendimento do CROOKES.
                </p>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                4. Armazenamento dos Dados
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Os Dados coletados são armazenados em local seguro. A qualquer momento o USUÁRIO poderá solicitar a exibição,
                  correção ou exclusão de seus Dados. Salvo casos específicos, poderemos manter os Dados coletados para
                  cumprimento legal, auditoria e preservação de direitos, pelo prazo necessário para cumprir essas
                  responsabilidades.
                </u>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.1.</b> Os Dados coletados estarão armazenados em ambiente seguro e controlado. Todavia, considerando que
                  nenhum sistema de segurança é infalível, o CROOKES se exime de quaisquer responsabilidades por eventuais danos
                  e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados dos sites, aplicativos e serviços
                  oferecidos pelo CROOKES, salvo nos casos em que tiver dolo ou culpa. Contudo, caso o CROOKES identifique que
                  alguma dessas situações ocorreu, notificará os USUÁRIOS afetados informando que houve violação de seus Dados e
                  indicando que adotem as medidas cabíveis.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.2.</b> Os Dados obtidos do USUÁRIO poderão ser armazenados em servidor próprio do CROOKES ou de terceiro
                  contratado para esse fim, sejam eles alocados no Brasil ou no exterior, podendo ainda ser armazenados por meio
                  de tecnologia de cloud computing e/ou outras que surjam futuramente, visando sempre a melhoria e
                  aperfeiçoamento das atividades do CROOKES. O CROOKES fará com que os terceiros que eventualmente mantenham os
                  servidores em que os Dados estejam armazenados mantenham padrões de segurança e controle conforme os padrões
                  legais aplicáveis.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.3.</b> Pelas ferramentas de atendimento disponibilizadas nos sites, aplicativos e serviços oferecidos
                  pelo CROOKES é facultado ao USUÁRIO (podendo estar sujeito a comprovar sua identidade):
                </p>

                <p>
                  <b>(i)</b> requerer o acesso aos Dados, incluindo sua exibição, retificação com a correção de dados
                  incompletos, inexatos ou desatualizados ou eliminação/exclusão dos Dados Pessoais que lhe dizem respeito;
                </p>

                <p>
                  <b>(ii)</b> requerer a eliminação/exclusão de todos os seus Dados Pessoais coletados e registrados
                  pelo CROOKES, desde que o contrato entre o USUÁRIO e o CROOKES tenha terminado, a Conta cancelada e o prazo
                  legal mínimo para fins de obrigações legais do CROOKES tenha decorrido;
                </p>

                <p>
                  <b>(iii)</b> revogar o consentimento para a futura coleta, tratamento, uso e processamento dos Dados
                  Pessoais que lhe dizem respeito ou restringir o processamento de Dados Pessoais. Isso não afetará a legalidade
                  do tratamento e processamento dos Dados Pessoais realizados antes da revogação, com base em seu consentimento
                  ou no legítimo interesse do CROOKES. A revogação do consentimento poderá impedir o USUÁRIO de utilizar em sua
                  plenitude ou mesmo inviabilizar o uso dos sites, aplicativos e serviços oferecidos pelo CROOKES.
                </p>

                <p>
                  <b>(iv)</b> solicitar a portabilidade dos seus Dados; e
                </p>

                <p>
                  <b>(v)</b> solicitar que o CROOKES não mais utilize os Dados Pessoais para propósitos de marketing.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.3.1.</b> Ainda que o USUÁRIO tenha solicitado a exclusão de seus dados e revogado seu consentimento, em
                  alguns casos específicos o CROOKES pode estar sujeito a Leis e regulações que impossibilitem a
                  exclusão/revogação dos Dados.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.3.2.</b> Os Dados Pessoais do USUÁRIO também serão excluídos quando estes não forem mais necessários,
                  exceto na ocorrência de justificativa legal ou contratual para a sua manutenção (exemplificativamente, para
                  cumprir eventual obrigação legal de retenção de dados ou necessidade de preservação destes para preservar
                  direitos e interesses legítimos de uma das partes envolvidas na contratação).
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.4.</b> As configurações de privacidade dos USUÁRIOS e os produtos e recursos que o USUÁRIO utiliza
                  influenciam os Dados do USUÁRIO que serão obtidos pelo CROOKES.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>4.5.</b> O CROOKES poderá, para fins de auditoria e preservação de direitos, permanecer com o histórico de
                  registro dos Dados do USUÁRIO, possuindo o CROOKES faculdade de excluí-los definitivamente mediante sua
                  conveniência ou nas hipóteses em que lei ou norma regulatória exigirem. O CROOKES também poderá manter
                  os Dados Anonimizados e versão anonimizada dos Dados para propósitos de estatística e estudos, mesmo após a
                  solicitação de exclusão pelo USUÁRIO ou após o término do prazo legal de guarda.
                </p>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                5. Disposições Gerais:
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Podemos atualizar esse documento a qualquer momento. Assim, consulte-o com frequência. Se terceirizarmos
                  qualquer atividade, garantiremos que as empresas contratadas obedeçam a todas as disposições deste documento.
                </u>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.1.</b> O teor desta Política de Privacidade poderá ser atualizado ou modificado a qualquer momento,
                  conforme a finalidade ou conveniência do CROOKES, tal qual para adequação e conformidade legal de disposição
                  de lei ou norma que tenha força jurídica equivalente, cabendo ao USUÁRIO verificá-la sempre que efetuar o
                  acesso aos sites, aplicativos ou serviços oferecidos pelo CROOKES.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.1.1.</b> Ocorrendo atualizações neste documento, o CROOKES notificará o USUÁRIO mediante as ferramentas
                  disponíveis nos sites, aplicativos e serviços prestados pelo CROOKES e/ou meios de contato fornecidos
                  pelo USUÁRIO. O USUÁRIO estará vinculado aos novos termos deste documento a partir da entrega da notificação
                  sobre as atualizações.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.2.</b> O contato do Encarregado/DPO estará no site da CROOKES. O USUÁRIO poderá entrar no endereço
                  eletrônico para esclarecer quaisquer dúvidas com relação às disposições constantes nesta Política de
                  Privacidade.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.3.</b> Caso considere que o CROOKES violou alguma disposição legal ou desta Política de Privacidade,
                  o USUÁRIO tem o direito de apresentar uma queixa à autoridade de supervisão apropriada, além de contatar
                  o CROOKES diretamente.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.4.</b> Caso empresas terceirizadas realizem o processamento de quaisquer Dados coletados pelo CROOKES L,
                  deverão respeitar as condições aqui estipuladas e as normas de Segurança da Informação do CROOKES,
                  obrigatoriamente.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>5.5.</b> Caso alguma disposição desta Política de Privacidade seja considerada ilegal ou ilegítima por
                  autoridade da localidade em que o USUÁRIO resida ou da sua conexão à internet, as demais condições
                  permanecerão em pleno vigor e efeito.
                </p>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                6. Lei Aplicável e Jurisdição
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <u>
                  Em eventuais ações judiciais aplica-se a lei brasileira e o foro da cidade de São Paulo, salvo ressalva
                  específica pela Legislação Aplicável (como o de domicílio do USUÁRIO).
                </u>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>6.1.</b> A presente Política de Privacidade será interpretada segundo a legislação brasileira, no idioma
                  português, sendo eleito o foro da cidade de São Paulo para dirimir qualquer litígio ou controvérsia envolvendo
                  o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela
                  Legislação Aplicável.
                </p>
              </Typography>

              <Typography gutterBottom variant="h4" component="h1" className={classes.subtitle}>
                7. Condições específicas
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                A seguir são apresentadas condições específicas aplicáveis a determinadas empresas/produtos/serviços do CROOKES,
                identificados em cada seção. Caso o USUÁRIO contrate com alguma das empresas ou utilize um ou mais dos
                produtos/serviços discriminados a seguir, as seguintes condições serão aplicáveis em complemento ao estabelecido
                na Política de Privacidade.
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.</b> Plataforma de Emissão de Laudos
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.1.</b> Caso o USUÁRIO utilize a plataforma online de prestação de serviços de Emissão de Laudos, as
                  seguintes condições complementares serão aplicáveis.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.2.</b> Ao utilizar a Plataforma de Emissão de Laudos, poderá ser solicitado ao USUÁRIO a apresentação
                  de documentos e informações adicionais.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.3.</b> Na hipótese de o CROOKES suspeitar que informações falsas foram disponibilizadas pelo USUÁRIO,
                  terceiros poderão ser acionados com o intuito de apurar a possibilidade de fraude; estes terão acesso às
                  informações disponibilizadas com o exclusivo objetivo de verificar a ocorrência ou não da possível fraude.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.4.</b> O CROOKES  poderá transferir Dados obtidos a outros parceiros, que serão necessárias, única e
                  exclusivamente, na consecução das atividades relacionadas com a Plataforma de Emissão de Laudos.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.5.</b> Além das finalidades descritas na Política de Privacidade, o CROOKES  ou terceiros prestadores
                  de serviços estão autorizados a utilizar as informações disponibilizadas pelos USUÁRIOS para os seguintes
                  propósitos:
                </p>

                <p>
                  <b>(i)</b> verificação da identidade do USUÁRIO;
                </p>

                <p>
                  <b>(ii)</b> identificar possível fraude;
                </p>

                <p>
                  <b>(iii)</b> administrar a Plataforma de Emissão d˙e Laudos;
                </p>

                <p>
                  <b>(iv)</b> tornar a página de acesso da Plataforma de Emissão de Laudos disponível aos seus usuários e
                  identificar os serviços de seus interesses;
                </p>

                <p>
                  <b>(vi)</b> realizar controles regulamentares requeridos pela legislação/regulação aplicáveis;
                </p>

                <p>
                  <b>(vii)</b> manter informados quaisquer terceiros possivelmente interessados e/ou relacionados à operação
                  desejada; e
                </p>

                <p>
                  <b>(viii)</b> garantir a execução da operação desejada.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1}>
                <p>
                  <b>7.1.6.</b>  O CROOKES  conta com recursos de proteção física, eletrônica e procedimental para a Plataforma
                  de Emissão de Laudos, que atendem aos padrões legais nacionais e internacionais de proteção a informações
                  pessoais, tais como mecanismos de autenticação de acesso com sistemas de autenticação dupla, assegurando a
                  individualização do responsável pelo tratamento dos registros, inventário detalhado de acessos à Plataforma de
                  Emissão de Laudos e uso de soluções de gestão de registros por técnicas que garantem a inviolabilidade dos
                  dados.
                </p>
              </Typography>

              <Typography variant="subtitle2" color="textSecondary" className={classes.text1} style={{ textAlign: 'center' }}>
                27 de outubro de 2020
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Container>
    </div>
  );
}

export default Privacy;
