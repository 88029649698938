import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import AuthPatient from '../screens/AuthPatient/AuthPatient';
import Privacy from '../screens/Privacy/Privacy';

const SignRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Route exact path="/" component={AuthPatient} />
      <Route path="/privacy" component={Privacy} />
    </BrowserRouter>
  );
};

export default SignRoutes;
