import axios from "axios";

interface AuthModel {
  email: string;
  password: string;
  domain: string | null;
}

export async function post(url: string, authModel: AuthModel): Promise<any> {
  try {
    return await axios.post(url, authModel);
  } catch (e: any) {
    if (e !== null) {
      return e.response;
    }
    return e;
  }
}

export const defaults = {
  headers: {
    Authorization: '',
  },
};