import React, { createContext, useState, useContext } from 'react';
import * as URL from '../constants/URL';
import * as api from '../services/patientService';
import { useAuth } from './authContext';

interface IPatientModel {
  documentNumber: string;
  hospitalID: string | null;
}

export interface IPatientResult {
  createdDate: Date;
  finishDate: Date | null;
  modalityName: string;
  patientName: string;
  reports: IReportModel[];
  studyDate: string;
  studyUID: string;
}

interface IPatientContextData {
  GetListAsync(patient: IPatientModel): Promise<void>;
  list: IPatientResult[];
  GetDocumentNumberAsync(): Promise<void>;
  documentNumber: string;
  errorMessage: string;
}

export interface IReportModel {
  uri: string;
  reportID: string;
  title: string;
}

const URL_PATIENT: string = `${URL.EXAM_API}/api/v1/ExamPatient/get-list`;
const PatientContext = createContext<IPatientContextData>({} as IPatientContextData);

export const PatientProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<IPatientResult[]>([]);
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [hospitalID, setHospitalID] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { Logout } = useAuth();

  async function GetListAsync(patient: IPatientModel): Promise<void> {
    patient.hospitalID = hospitalID;
    const response = await api.getExamListAsync(URL_PATIENT, patient);
    if (response.status === 200) {
      const exams = response.data.data.map(async (x: IPatientResult) => {
        const { reports, studyDate, createdDate, studyUID, patientName, modalityName, finishDate } = x;

        const promises = reports.map(async (y: IReportModel) => {
          const uri: string = `${URL.REPORT_API}/api/v1/report/${y.reportID}/tab`;
          return await api.getReportListAsync(uri);
        });

        const all = await Promise.all(promises);
        const titles = all.map((y: any) => {
          const data = y.data.data;
          const uri = reports.filter((z: IReportModel) => z.reportID === data.reportID).map((z: IReportModel) => z.uri)[0];

          const model: IReportModel = {
            reportID: data.reportID,
            title: data.title,
            uri,
          };

          return model;
        });

        return {
          reports: titles,
          studyDate,
          createdDate,
          studyUID,
          patientName,
          modalityName,
          finishDate,
        };
      });

      const result: IPatientResult[] = await Promise.all(exams);
      setList(result);
    } else if (response.status === 400 && response.data) {
      setErrorMessage(response.data.error);
    }
  }

  async function GetDocumentNumberAsync(): Promise<void> {
    const url = `${URL.MAIN_API}/api/v1/patient`;
    const response = await api.getDocumentNumberAsync(url);
    if (response.status === 200) {
      setDocumentNumber(response.data.documentNumber);
      setHospitalID(response.data.hospitalID);
    } else if (response.status === 401) {
      Logout();
    } else if (response.status === 400 && response.data) {
      setErrorMessage(response.data.error);
    }
  }

  return (
    <PatientContext.Provider value={{ GetListAsync, list, GetDocumentNumberAsync, documentNumber, errorMessage: errorMessage }}>
      {children}
    </PatientContext.Provider>
  );
};

export function usePatient() {
  const context = useContext(PatientContext);
  return context;
}
