import axios from 'axios';

interface ExamPatient {
  documentNumber: string;
  hospitalID: string | null;
}

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('@App:token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export async function getExamListAsync(url: string, examPatient: ExamPatient): Promise<any> {
  try {
    return await axios.post(url, examPatient);
  } catch (e: any) {
    if (e !== null) {
      return e.response;
    }
    return e;
  }
}

export async function getReportListAsync(url: string): Promise<any> {
  try {
    return await axios.get(url);
  } catch (e: any) {
    if (e !== null) {
      return e.response;
    }
    return e;
  }
}

export async function getDocumentNumberAsync(url: string): Promise<any> {
  try {
    return await axios.get(url);
  } catch (e: any) {
    if (e !== null) {
      return e.response;
    }
    return e;
  }
}

export const defaults = {
  headers: {
    Authorization: '',
  },
};
