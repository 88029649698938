import React, { createContext, useState, useEffect, useContext } from 'react';
import * as URL from '../constants/URL';
import * as api from '../services/authService';
import * as patient from '../services/patientService';

interface AuthContextData {
  signed: boolean;
  user: object | null;
  Login(user: AuthModel): Promise<void>;
  Logout(): void;
  ClearMessage(): void;
  errorMessage: string | null;
  domain(): void;
}

interface AuthModel {
  email: string;
  password: string;
  domain: string | null;
}

const URL_LOGIN: string = `${URL.MAIN_API}/api/v1/login`;
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {

  const [user, setUser] = useState<object | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const storagedUser = sessionStorage.getItem('@App:user');
    const storagedToken = sessionStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      patient.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  async function Login(authModel: AuthModel) {
    authModel.domain = domain();
    const response = await api.post(URL_LOGIN, authModel);
    if (response.status === 200) {
      setUser(response.data.userName);
      api.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
      patient.defaults.headers.Authorization = `Bearer ${response.data.accessToken}`;
      sessionStorage.setItem('@App:user', JSON.stringify(response.data.userName));
      sessionStorage.setItem('@App:token', response.data.accessToken);
    } else if (response.status === 400) {
      if (response.data.hasError) {
        setError(response.data.errors[0].errorMessage);
      }
    }
  }

  function domain() {
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      const url = process.env.REACT_APP_PATIENT_REPORT;
      return getDomain(url);
    }
    return getDomain(window.location.href);
  }

  function getDomain(url: string | undefined): string {
    if (url !== undefined) {
      return url.substring(0, url.indexOf(".")).replace("https://", "");
    }
    return "";
  }

  function Logout(): void {
    setUser(null);
    sessionStorage.removeItem('@App:user');
    sessionStorage.removeItem('@App:token');
    api.defaults.headers.Authorization = "";
  }

  function ClearMessage(): void {
    setError(null);
  }

  return (
    <AuthContext.Provider
      value={{ signed: Boolean(user), user, Login, Logout, errorMessage: error, ClearMessage, domain }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}