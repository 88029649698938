import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useAuth } from '../../contexts/authContext';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CookieModal from '../../components/notification/CookieModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AuthPatient() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);
  const { Login, errorMessage, ClearMessage } = useAuth();

  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    cleanState();
    if (!isValid()) {
      return;
    }
    await Login({ email, password, domain: null });
  }

  function isValid(): boolean {
    var status = true;
    if (email === '' || email === undefined) {
      setEmailError('Informar CPF');
      setHasEmailError(true);
      status = false;
    }
    if (password === '' || password === undefined) {
      setPasswordError('Informar senha');
      setHasPasswordError(true);
      status = false;
    }
    return status;
  }

  const cleanState = () => {
    setHasPasswordError(false);
    setHasEmailError(false);
    setEmailError('');
    setPasswordError('');
  };

  const classes = useStyles();

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: string) => {
    if (field === 'email') {
      setEmail(event.target.value);
    }
    if (field === 'password') {
      setPassword(event.target.value);
    }
  };

  const handleClose = (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setMessage('');
    ClearMessage();
  };

  useEffect(() => {
    function messages() {
      if (errorMessage !== null) {
        setOpen(true);
        setMessage(errorMessage);
      }
    }
    messages();
  }, [errorMessage]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <CookieModal />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Autenticação
        </Typography>
        <form className={classes.form} noValidate onSubmit={(e) => handleLogin(e)}>
          <TextField
            helperText={emailError}
            error={hasEmailError}
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            id="documentNumber"
            label="CPF"
            name="documentNumber"
            autoFocus
            value={email}
            onChange={(e) => onChange(e, 'email')}
          />
          <TextField
            helperText={passwordError}
            error={hasPasswordError}
            variant="outlined"
            margin="normal"
            required={true}
            fullWidth
            name="password"
            label="Código"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => onChange(e, 'password')}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Acessar
          </Button>
        </form>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {message}
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
}
