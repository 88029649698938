/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import { useAuth } from '../../contexts/authContext';
import { usePatient, IPatientResult, IReportModel } from '../../contexts/patientContexts';
import { Grid } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPapper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Home() {
  const { GetListAsync, list, GetDocumentNumberAsync, documentNumber, errorMessage } = usePatient();
  const { Logout } = useAuth();
  const [hasDocument, setHasDocument] = useState<boolean>(false);

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  useEffect(() => {
    async function getAsync() {
      if (!hasDocument) {
        await GetDocumentNumberAsync();
        setHasDocument(true);
      }
    }
    getAsync();
  }, []);

  useEffect(() => {
    async function getListExamsAsync() {
      if (hasDocument) {
        GetListAsync({ documentNumber, hospitalID: null });
        setHasDocument(false);
      }
    }
    getListExamsAsync();
  }, [GetListAsync, documentNumber, hasDocument]);

  const handleOver = () => {
    Logout();
  };

  function renderMainTable() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Grid item>
          <Paper className={classes.paper}>
            {list.map((row: IPatientResult) => {
              return (
                <TableContainer component={Paper} key={row.studyUID}>
                  <Table className={classes.table} size="small" aria-label="lista de exames">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell component="td">
                          <strong>Nome</strong>: {row.patientName}
                        </StyledTableCell>
                        <StyledTableCell component="td">
                          <strong>Data do Exame</strong>:{' '}
                          {row.studyDate || new Date(row.createdDate).toLocaleDateString('pt-BR')}
                        </StyledTableCell>
                        <StyledTableCell component="td">
                          <strong>Modalidade</strong>: {row.modalityName}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {row.reports.map((x: IReportModel) => {
                        return (
                          <TableRow key={x.reportID}>
                            <TableCell component="td" colSpan={2}>
                              {x.title}
                            </TableCell>
                            <TableCell component="td" align="right">
                              <a href={x.uri} target="_blank" rel="noreferrer" title={`Ver o laudo ${x.title}`}>
                                Ver o laudo
                              </a>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            })}
          </Paper>
        </Grid>
      </Box>
    );
  }

  function renderErrorMessage() {
    return (
      <Modal open={errorMessage !== ''} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.modalPapper}>
          <h2 id="simple-modal-title">Ops! Encontramos um erro</h2>
          <p id="simple-modal-description">{errorMessage}</p>
          <p>Por favor, tente novamente mais tarde</p>
          <Button variant="contained" color="primary" onClick={handleOver}>
            Entendi
          </Button>
        </div>
      </Modal>
    );
  }

  function renderLoading() {
    return (
      <div>
        <span>Carregando...</span>
      </div>
    );
  }

  function renderNoReports() {
    return (
      <div>
        <Alert severity="info">
          <AlertTitle>Aviso</AlertTitle>
          Nenhum laudo disponível no momento
        </Alert>
      </div>
    );
  }

  return errorMessage !== ''
    ? renderErrorMessage()
    : list !== null && list.length > 0
    ? renderMainTable()
    : list !== null && list.length === 0
    ? renderNoReports()
    : renderLoading();
}
