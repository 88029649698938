import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import TopAppBar from '../components/appBar/TopAppBar';

import Home from '../screens/Home/Home';

import { PatientProvider } from '../contexts/patientContexts';


const OtherRoutes: React.FC = () => {
  return (
    <PatientProvider>
      <BrowserRouter>
        <TopAppBar>
          <Route path="/" component={Home} />
        </TopAppBar>
      </BrowserRouter>
    </PatientProvider>    
  );
};

export default OtherRoutes;