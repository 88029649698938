import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    maxWidth: 345,
  },
}));

const PORTAL_COOKIE_PRIVACY = 'PORTAL_COOKIE_PRIVACY';

function CookieModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    !localStorage.getItem(PORTAL_COOKIE_PRIVACY) && handleOpen();
  }, []);

  const accept = () => {
    localStorage.setItem(PORTAL_COOKIE_PRIVACY, '1');
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Atenção
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa{' '}
                    <Link href="/privacy" target="_blank">
                      Política de Privacidade
                    </Link>{' '}
                    e, ao continuar navegando, você concorda com estas condições.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="large" color="primary" style={{ marginRight: 'auto', marginLeft: 'auto' }} onClick={accept}>
                  Estou de acordo
                </Button>
              </CardActions>
            </Card>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default CookieModal;
